import React, {FC, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import CreatePost from "../components/Posts/CreatePost";


const BlogPage: FC = () => {

    useEffect(()=>{
        let htmlRoot = document.getElementsByTagName("html");
        htmlRoot[0].classList.add('dark-theme');
    },[])

    return (
        <div className=''>
            <div className='w-full h-1 bg-slate-400 rounded-md'></div>
            <div className=''>

                        <CreatePost/>
            </div>
        </div>
    );
};

export default observer(BlogPage);