import React, {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import Editoor from "../Posts/Editor/Editoor";
import {Context} from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import {useNavigate} from "react-router-dom";
import {RoutesList} from "../../routes";

const CreateBanner: FC = () => {
    const {store} = useContext(Context)
    const {postStore} = useContext(Context)
    const navigate = useNavigate();

    const [modalVisible, setModalVisible] = useState(true)
    const [visible, setVisible] = useState(false)
    const [editorHtml, setEditorHtml] = useState('');
    const [priority, setPriority] = useState<string>('');
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [bannerLink, setBannerLink] = useState<string>('')
    const [isTop, setIsTop] = useState<any>(false)

    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9]*$/.test(e.target.value)){
            setPriority(e.target.value);
        }
    }

    const CreatePost = async() => {
        const thisDate = new Date()

        const date = thisDate.toLocaleDateString()
        const time = thisDate.toLocaleTimeString()

        const post = editorHtml;
        const resultLink = () => {
            if(bannerLink[0] == '/'){
                return bannerLink;
            }else if (bannerLink.indexOf('https://') !== -1) {
                return bannerLink;
            } else {
                return 'https://' + bannerLink;
            }
        };

        const link = resultLink();
        const response = await postStore.addBanner(date, time,  title, description, post, link, priority)
        navigate(RoutesList.BANNERS_LIST_ROUTER)
        store.setSideBarActiveID(1)
    }

    return (
        <div  className='p-4 bg-light rounded-md '>
            <div className='flex justify-between pb-4'>
                <div className='w-[500px] flex justify-between'>
                    <h3>Додавання банер</h3>
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'1'}
                            value={title}
                            placeholder={'Заголовок'}
                            icon={'text'}
                            void={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'2'}
                            value={description}
                            placeholder={'Опис банера'}
                            icon={'text'}
                            void={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'3'}
                            value={bannerLink}
                            placeholder={'Посилланя на офер'}
                            icon={'link-alt'}
                            void={(e) => setBannerLink(e.target.value)}
                        />
                    </div>
                    <div className='pt-3'>
                        <div className="input-group">
                            <MyInput
                                placeholder={'Пріоритетність'}
                                type={'text'}
                                value={priority}
                                void={handleChoosePriority}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-80 bg-white text-black'>
                <Editoor
                    editorHtml={editorHtml}
                    setEditorHtml={setEditorHtml}
                    placeholder='Write here...'/>
            </div>
            <div className='flex justify-between w-full pt-4'>
                <button
                    onClick={CreatePost}
                    className='btn btn-secondary rounded-md'
                >
                    Додати банер
                </button>
            </div>
        </div>
    );
};

export default observer(CreateBanner);