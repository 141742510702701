import React, {FC} from 'react';

const Loading: FC = () => {
    const logo = {img: require('../../../images/logo.png')};

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-slate-800/90 z-50">
            <div className="text-2xl text-cyan-600">
                Loading...
            </div>
        </div>
    );
};

export default Loading;