import React, {FC, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Navigate, Route, Routes} from "react-router-dom";
import {publicRoutes, RoutesList} from "./index";

const AppRouter: FC = () => {
    const {store} = useContext(Context)

    return (
            <Routes>
                {publicRoutes.map(route =>
                    <Route path={route.path}
                           Component={route.component}
                           key={route.path}
                    />
                )}
                <Route path="*" element={<Navigate replace to={RoutesList.MAIN_ROUTER}/>} />
                <Route path="/admin/*" element={<Navigate replace to={RoutesList.AUTH_ROUTER}/>} />
            </Routes>
    );
};

export default observer(AppRouter);