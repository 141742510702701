import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from "axios";
import {API_URL} from "../http";

export default class Store {
    user = {} as IUser
    isAuth = false
    isLoading = false
    isError = ''
    sideBarActiveID = null

    constructor() {
        makeAutoObservable(this)
    }

    setAuth(bool: boolean) {
        this.isAuth = bool
    }

    setUser(user: IUser) {
        this.user = user
    }

    setIsLoading(bool: boolean) {
        this.isLoading = bool
    }

    setIsError(error: string) {
        this.isError = error
    }

    setSideBarActiveID(id:any) {
        this.sideBarActiveID = id
    }

    async registration(username: string, password: string, role: string) {
        try {
            this.setIsLoading(true)
            const response = await AuthService.registration(username, password, role)
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
            this.setIsLoading(false)
        } catch (e: any) {
            this.setIsError(e.response?.data?.message)
            this.setIsLoading(false)
        }
    }

    async login(username: string, password: string) {
        try {
            this.setIsLoading(true)
            const response = await AuthService.login(username, password)
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
            this.setIsLoading(false)
        } catch (e: any) {
            this.setIsLoading(false)
            this.setIsError(e.response?.data?.message)
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout()
            localStorage.removeItem('token')
            this.setAuth(false)
            this.setUser({} as IUser)
        } catch (e: any) {
            this.setIsError(e.response?.data?.message)
        }
    }
    async checkIsAuth() {
        this.setIsLoading(true)
        try {
            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
            this.setIsLoading(false)
        } catch (e: any) {
            this.setIsError(e.response?.data?.message)
        } finally {
            this.setIsLoading(false)
        }
    }

}