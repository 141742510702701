import React, {FC, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import SideBar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";
import AppRouter from "./routes/AppRouter";
import {Context} from "./index";
import PrivateAppRouter from "./routes/PrivateAppRouter";

const AuthPanel:FC = () => {
    const {store} = useContext(Context)
    //
    // if(store.isError) {
    //     return <div>{store.isError}</div>
    // }

    useEffect(()=>{

        if (localStorage.getItem('token')) {
            store.checkIsAuth().then(res => console.log(res))
        }

    },[])

    return (
        <div className="flex" id='html'>
            <div className=''>
                <SideBar/>
            </div>
            <div className="p-4 col">
                <div>
                    <Header/>
                </div>
                <div className='page-wrapper'>
                    <div className='page-content'>
                        <PrivateAppRouter/>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default observer(AuthPanel);