import React from 'react';
import Blog from "./Posts/Blog";
import Footer from "./Footer/Footer";
import News from "./Header/News";
import { observer } from "mobx-react-lite";

const Main = () => {
    return (
        <div>
            <div className='w-100'>
                <News />
            </div>
            <div className='w-100'>
                <div className='container mx-auto'>
                    <Blog />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default observer(Main);