import React from 'react';

const Footer = () => {
    return (
        <div className='w-full bg-zinc-900 flex justify-center text-white p-6 '>
            @ All rights reserved.
        </div>
    );
};

export default Footer;