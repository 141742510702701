import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Store from "./store/store";
import {BrowserRouter} from "react-router-dom";
import PostStore from "./store/postStore";
import {Storeblog} from "./store/storeblog";


interface StoreState {
    storeblog: Storeblog,
    store: Store,
    postStore: PostStore
}

const storeblog = new Storeblog()
const store = new Store()
const postStore = new PostStore()


export const Context = createContext<StoreState>({
    storeblog,
    store,
    postStore
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
    <Context.Provider value={{
        storeblog,
        store,
        postStore
    }}>
            <App/>
    </Context.Provider>
    </BrowserRouter>
);

