import React, {FC} from 'react';
import styled from 'styled-components';
import {observer} from "mobx-react-lite";

interface PopupModalBannerItem{
    title: string,
    img: string
}

const PopupModalBannerItemBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0.5em;
  width: 500px;
  height: 100px;
  background: #1c1c1c;
  outline: none;
  border-bottom: 1px solid #f0f0f0;
  font-size: 1em;
  letter-spacing: 2px;
  color: #f0f0f0;
  cursor: pointer;
  overflow: hidden;
`;

const PopupModalBannerItemImg = styled.img`
  width: 100px;
  height: auto;
  
`;

const PopupModalBannerItemText = styled.p`
      padding: 0.5em;
`;

const PopupModalBannerItem: FC<PopupModalBannerItem> = (props) => {

    return(
        <PopupModalBannerItemBox>
            <PopupModalBannerItemImg src={props.img}/>
            <PopupModalBannerItemText>{props.title}</PopupModalBannerItemText>
        </PopupModalBannerItemBox>
    )

}


export default observer(PopupModalBannerItem);