import React, {FC, useContext, useEffect, useState, KeyboardEvent} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import MyButton from "./UI/MyButton/MyButton";
import MyInput from "./UI/MyInput/MyInput";
import {useNavigate} from "react-router-dom";
import {RoutesList} from "../routes";

const LoginForm: FC = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [visible, setVisible] = useState<boolean>(false)

    const logo = {img: require('../images/logo.png')};

    useEffect(()=> {

        let htmlRoot = document.getElementsByTagName("html");
        htmlRoot[0].classList.add('dark-theme');
    },[]);


    const LogIn = async () => {
        await store.login(username, password)
        if (!store.isAuth) {
            setVisible(true)
        }else {
            navigate(`${RoutesList.POSTS_LIST_ROUTER}`);
            store.setSideBarActiveID(0)
        }
    }

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            LogIn(); // Виклик функції логіну при натисканні "Enter"
        }
    };

    return (
        <div className='card' onKeyDown={handleKeyPress}>
            <div className="card-body p-4">
                <div className='d-flex justify-center w-full mx-auto'>
                        <img src={logo.img} alt="" className='logo-icon m-1'/>
                        <h3 className='text-uppercase m-1'>Dashrok</h3>
                </div>
                <div className='col-md-12'>
                    <MyInput
                        type='text'
                        id={'1'}
                        value={username}
                        placeholder={'username'}
                        icon={'user'}
                        void={(e) => setUsername(e.target.value)}

                    />
                </div>
                <div className="relative my-6">
                    <MyInput
                        type='password'
                        id={'2'}
                        value={password}
                        placeholder={'password'}
                        icon={'lock-alt'}
                        void={(e) => setPassword(e.target.value)}

                    />
                </div>
                <div className="my-3 ">
                    <MyButton text='Log In'  function={LogIn}/>
                </div>
            </div>
        </div>
    );
};

export default observer(LoginForm);
