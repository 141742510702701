import React, {FC, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Navigate, Route, Routes} from "react-router-dom";
import {privateRoutes, publicRoutes, RoutesList} from "./index";

const PrivateAppRouter: FC = () => {
    const {store} = useContext(Context)

    return (
            <Routes>
                {privateRoutes.map(route =>
                    <Route path={route.path}
                           Component={route.component}
                           key={route.path}
                    />
                )}
                <Route path="/login" element={<Navigate replace to={RoutesList.POSTS_LIST_ROUTER} />} />
            </Routes>
    );
};

export default observer(PrivateAppRouter);