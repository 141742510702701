import React, {FC, useContext, useState} from 'react';
import {Link} from "react-router-dom";
import {Context} from "../../../index";

interface SideButtonProps {
    text: string;
    link: string;
    active: boolean;
    id: number;
    icon: React.ReactNode;
}

const SideButton: FC<SideButtonProps> = (props) => {
    const {store} = useContext(Context)

    const handleClick = () => {
        store.setSideBarActiveID(props.id); // Встановлення активного ID при кліку
    };

    return (
            <Link
                to={props.link}
                onClick={handleClick}
                className={props.active ? 'active_btn' : ''}
            >
                <a aria-expanded="false" className={props.active ? 'active_btn' : ''}>
                    <div className="parent-icon"><i className={`bx bx-${props.icon}`}></i>
                    </div>
                    <div className="menu-title">{props.text}</div>
                </a>
            </Link>
    );
};

export default SideButton;