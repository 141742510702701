import {AxiosResponse} from "axios";
import {AuthResponse} from "../models/response/AuthResponse";
import $api from "../http";
import {IBanner, IPost} from "../models/IPost";
import {PostsResponse} from "../models/response/PostsResponse";
import {BannersResponse} from "../models/response/BannersResponse";
import {PopupBannersResponse, UpdatePopupBannersResponse} from "../models/response/PopupBanners";

export default class PostService {
    static async addPost(date: string, time: string, isTop: boolean, title: string, description: string,category:string,  post: string, priority: number): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/blog/addPost', {date, time, isTop, title, description, category, post, priority})
    }

    static async editPost(id: string, date: string, time: string, isActive: boolean, title: string, description: string,category:string,  post: string, priority: number): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/blog/editPost', {id, date, time, isActive, title, description,category, post, priority})
    }

    static async getPosts(page: number, limit: number): Promise<AxiosResponse<PostsResponse>> {
        return $api.post<PostsResponse>(`/blog/getPosts/${page}/${limit}`)
    }

    static async addBanner(date: string, time: string,  title: string, description: string, post: string,  link: string, priority: number): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/banner/addBanner', {date, time, title, description, post, link, priority})
    }

    static async getBanners(page: number, limit: number): Promise<AxiosResponse<BannersResponse>> {
        return $api.get<BannersResponse>(`/banner/getBanners/${page}/${limit}`)
    }

    static async editBanner(id: string, date: string, time: string,  isActive: boolean, title: string,  description: string, post: string,  link: string, priority: number): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/banner/editBanner', {id, date, time, isActive, title, description, post, link, priority})
    }

    static async getPopupBanners(): Promise<AxiosResponse<PopupBannersResponse[]>> {
        return $api.get<PopupBannersResponse[]>('/popup/getPopupBanner')
    }

    static async updatePopupBanners(popupBannersList:string[]): Promise<AxiosResponse<UpdatePopupBannersResponse[]>> {
        return $api.post<UpdatePopupBannersResponse[]>('/popup/addPopupBanner', {popupBannersList})
    }
}