import React, {FC, useEffect, useState} from 'react';
import MyInput from "../UI/MyInput/MyInput";
import ModalAgree from "../ModalAgree/ModalAgree";
import MyButton from "../UI/MyButton/MyButton";
import OneSignalService from "../../services/OneSignalService";
import {ISignal} from "../../models/ISignal";

interface SignalTokenProps{
    token: ISignal
}
const SignalToken:FC<SignalTokenProps> = (props) => {
    const [tokenValue, setTokenValue] = useState<string>('No token');
    const [openModalAgree, setOpenModalAgree] = useState<boolean>(false)

    useEffect(()=>{
        props.token ? setTokenValue(props.token.signal) : setTokenValue('No token');
        console.log('token true' , props.token.signal)
    },[])
    const Edit = async () => {
        const id = props.token._id;
        const signalToken = tokenValue;

        const response = await OneSignalService.editSignalToken(id, signalToken)
        console.log(id, signalToken , response)
    }

    const handleEditToken = () => {
        setOpenModalAgree(true);
    }
    const isEditToken = (status: boolean) => {
        if(status){
            Edit();
        }
        setOpenModalAgree(false);
    }
    return (
        <div className='card'>
            {
                openModalAgree ? <ModalAgree handleAgreement={isEditToken}/> : false
            }
            <div className="card-body p-4">
                <div className='col-md-12'>
                    <h4>OneSignal Token</h4>
                    <p className='text-secondary'>{ props.token.signal ? props.token.signal : "No token" }</p>
                </div>

                <div className='col-md-12'>
                    <MyInput
                        type='text'
                        id={'t1'}
                        value={tokenValue}
                        placeholder={'token'}
                        icon={'key'}
                        void={(e) => setTokenValue(e.target.value)}
                    />
                </div>
                <div className="my-3 ">
                    <MyButton text='Edit token' function={handleEditToken}/>
                </div>
            </div>
        </div>
    );
};

export default SignalToken;