import React, { useContext, useEffect, useState } from 'react';
import AppRouter from "./routes/AppRouter";
import Header from "./publickArticle/components/Header/Header";
import Category from "./publickArticle/components/Header/Category";
import { useLocation } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import ModalBanners from './publickArticle/components/ModalBanners/ModalBanners';
import { Context } from './index';

const PublickPanel = () => {
    const locate = useLocation();
    const { storeblog } = useContext(Context)

    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState(setTimeout(() => { }, 0));

    useEffect(() => {
        const i = setTimeout(() => {
            setShowModal(true);
        }, 60000);
        setTimer(i)
    }, [showModal])

    useEffect(() => {
        storeblog.GetPopupBanners();
    }, []);

    const closeModal = () => {
        setShowModal(false)
        clearTimeout(timer)
    }



    return (
        <>
            {locate.pathname !== '/login'
                ? <div>
                    <Header />
                    <Category />
                    <AppRouter />
                    {showModal &&
                        <ModalBanners onClick={closeModal} />
                    }
                </div>
                : <AuthPage />
            }
        </>
    );
};

export default PublickPanel;