import React, {FC, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import CreateBanner from "../components/Banners/CreateBanner";


const BlogPage: FC = () => {

    const [change, setChange] = useState<boolean>(false)

    useEffect(()=>{
        let htmlRoot = document.getElementsByTagName("html");
        htmlRoot[0].classList.add('dark-theme');
    },[])

    const ChangeComponent = (bool: boolean) => {
        setChange(bool)
    }

    return (
        <div className=''>
            <div className='w-full h-1 bg-slate-400 rounded-md'></div>
            <div className=''>

                <CreateBanner/>

            </div>
        </div>
    );
};

export default observer(BlogPage);