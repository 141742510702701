import React, {FC} from 'react';
import {buttonStyles} from "../../CSS/StylesUI";

interface MyButtonProps {
    text: string,
    icon?: React.ReactNode,
    function: () => void,
}

const MyButton: FC<MyButtonProps> = (props) => {
    return (
        <button
            className='btn btn-secondary px-5'
            onClick={props.function}
        >
            {props.text}
        </button>
    );
};

export default MyButton;