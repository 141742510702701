import React, {FC, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {RoutesList} from "../../routes";
import {mainTextStyle} from "../../pages/PagesCSS/PagesStyle";
import {Link} from "react-router-dom";

const Header: FC = () => {
    const {store} = useContext(Context)
    return (
        <header>
            <div className='topbar d-flex align-items-center justify-content-end'>
                <Link to={RoutesList.MAIN_ROUTER} className='d-flex align-items-center'>
                    <nav className="navbar navbar-expand gap-3">
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center gap-3"
                               href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {/*<h3 className="user-img text-center text-uppercase">{store.user.username.charAt(0)}</h3>*/}
                                    <div className="user-info">
                                        <p className="user-name mb-0">{store.user.username}</p>
                                        <p className="designattion mb-0">{store.user.role}</p>
                                    </div>
                            </a>
                        </div>
                    </nav>
                </Link>
            </div>
        </header>
    );
};

export default observer(Header);