import React, {FC, useContext, useState} from 'react';
import cl from "./MyModal.module.css"
import {Context} from "../../../index";

interface MyModalProps {
    void?: () => void,
    visible?: boolean,
    description?: string,
}

const MyModal: FC<MyModalProps> = (props) => {
    const {store} = useContext(Context)
    const rootClasses = [cl.myModal]
    if (props.visible) {
        rootClasses.push(cl.active)
    }

    const Clear = () => {
        store.setIsError('')
    }

    return (
        <div className={rootClasses.join(' ')}>
            <div className='rounded-md'>
                <div className={cl.myModalContent} onClick={(e) => e.stopPropagation()}>
                    {props.description} <br/>
                    <button onClick={Clear}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default MyModal;