import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import PostItem from "./PostItem";
import {Context} from "../../../index";
import {useParams} from "react-router-dom";

const PostList = () => {
    const {storeblog} = useContext(Context)
    const limit = 10;
    const {id, category} = useParams()
    const lastElement = useRef()
    const observer = useRef()

    useEffect(() => {
        if (storeblog.isLoading) return;
        if(observer.current ) observer.current.disconnect()
        var callback = function (entries, observer) {

            if(entries[0].isIntersecting && storeblog.pages < storeblog.totalPages){
                storeblog.setPages(storeblog.pages + 1)
            }
        }
        observer.current = new IntersectionObserver(callback)
        observer.current.observe(lastElement.current)
    }, [storeblog.isLoading])

    useEffect(() => {
        console.log(category, 'category')
        storeblog.GetPosts(storeblog.pages, limit, category?.toUpperCase() || '')
    }, [category, storeblog.pages, id])


    return (
        <div className='row mt-4 row-cols-1 row-cols-md-2 row-cols-lg-4 gy-4 gy-lg-0'>
            {storeblog.posts.map((e, i) =>
                <PostItem key={i} post={e}/>
            )}
            <div ref={lastElement} style={{height: 1, width: 1}}></div>
        </div>
    );
};

export default observer(PostList);