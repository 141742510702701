import React, {FC, useContext, useState} from 'react';
import {Context} from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import Editoor from "./Editor/Editoor";

interface CreateTaskModalProps {
    postId: string,
    post: string,
    title: string,
    isActive: boolean,
    category: string,
    description: string,
    priority: string,
    handlePopUpOpen: () => void,
}

const EditPostModal: FC<CreateTaskModalProps> = (props) => {
    const {postStore} = useContext(Context)

    const [modalVisible, setModalVisible] = useState(true)
    const [visible, setVisible] = useState(false)
    const [editorHtml, setEditorHtml] = useState(props.post);
    const [title, setTitle] = useState<string>(props.title)
    const [description, setDescription] = useState<string>(props.description)
    const [priority, setPriority] = useState<string>(props.priority);
    const [isActive, setIsActive] = useState<boolean>(props.isActive)
    const [isShowCategory, setIsShowCategory] = useState<any>(false);
    const [categoryPost, setCategoryPost] = useState<any>(props.category);

    const handleShowCategories = () => {
        setIsShowCategory(!isShowCategory);
    }

    const handleChooseCategories = (e: any) => {
        setCategoryPost(e.target.innerText);
        setIsShowCategory(!isShowCategory);
        console.log(categoryPost, e.target.innerText)
    }

    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9]*$/.test(e.target.value)){
            setPriority(e.target.value);
        }
    }

    const setArticleIsActive = () => {
        setIsActive(!isActive)
    }

    const Close = () => {
        props.handlePopUpOpen()
    }

    const EditPost = async () => {
        const thisDate = new Date()

        const id = props.postId;
        const date = thisDate.toLocaleDateString()
        const time = thisDate.toLocaleTimeString()

        const post = editorHtml;
        const category = categoryPost;

        const response = await postStore.editPost(id, date, time, isActive, title, description, category, post, priority)
        props.handlePopUpOpen()
        postStore.getPosts(1,20)
        console.log(response)
    }

    return (

        <div className="modal show edit_post" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content p-4">
                    <div className='d-flex flex-column align-items-start'>
                        <h3>Редагування статті</h3>
                        <div className='h-9 w-100'>
                            <MyInput
                                type='text'
                                id={'1'}
                                value={title}
                                placeholder={'Заголовок'}
                                icon={'text'}
                                void={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className='h-9 w-100'>
                            <MyInput
                                type='text'
                                id={'2'}
                                value={description}
                                placeholder={'Опис статті'}
                                icon={'text'}
                                void={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className='pt-3'>
                            <p className='text-uppercase mb-1 text-secondary'>Категорія</p>
                            <div className="input-group">
                                <button className="btn btn-outline-secondary dropdown-toggle w-100"
                                        onClick={handleShowCategories}
                                >{categoryPost}
                                </button>
                                <ul className={"dropdown-menu w-50 " + (isShowCategory ? 'show' : '')}>
                                    <li className="dropdown-item" onClick={(e) => handleChooseCategories(e)}>FINANCE
                                    </li>
                                    <li className="dropdown-item" onClick={(e) => handleChooseCategories(e)}>HEALTH</li>
                                    <li className="dropdown-item" onClick={(e) => handleChooseCategories(e)}>ANOTHER
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <div className="input-group">
                                <MyInput
                                    placeholder={'Пріоритетність'}
                                    type={'text'}
                                    value={priority}
                                    void={handleChoosePriority}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='h-9 pt-4'>
                        <div className="form-check">
                            <input className={"form-check-input " + (isActive ? 'bg-black border-black' : '')}
                                   onChange={setArticleIsActive}
                                   checked={isActive}
                                   type="checkbox"
                                   value=""
                                   id="flexCheckDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">Показати</label>
                        </div>
                    </div>
                    <div className='h-80 bg-white text-black'>
                        <Editoor
                            editorHtml={editorHtml}
                            setEditorHtml={setEditorHtml}
                            placeholder='Write here...'/>
                    </div>
                    <div className='flex justify-between w-75 pt-4'>
                        <button
                            onClick={Close}
                            className='btn btn-light rounded-md w-25'
                        >
                            Назад
                        </button>
                        <button
                            onClick={EditPost}
                            className='btn btn-secondary rounded-md mx-2 w-25'
                        >
                            Зберегти зміни
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EditPostModal;