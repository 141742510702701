import React, {useContext, useEffect, useState} from 'react';
import "../Posts/ArticleItem.css";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const BannerItem = ({link, post, title, id, articleId}) => {
    const {storeblog} = useContext(Context);
    const [photoSRC, setPhotoSRC] = useState('')
    console.log(id)

    useEffect(()=>{
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(post, 'text/html');
        const imgTags = parsedHtml.getElementsByTagName('img');
        for (let i = 0; i < imgTags.length; i++) {
            const src = imgTags[0].getAttribute('src');
            setPhotoSRC(src);
        }
    },[]);

    const utm_medium = `?utm_medium=${articleId}`
    const utm_content = `&utm_content=${id}`

    const updateUtmContent = (urlParams, newValue, utm_medium, utm_content) => {
        let params = new URLSearchParams(urlParams);
        let resultParams = ''

        if (params.has('utm_content')) {
            params.set('utm_content', newValue);
            resultParams = utm_medium + '&' + params
        } else {
            resultParams = utm_medium + '&' + params + '&' + utm_content
        }

        return resultParams.toString();
    }

    const params = updateUtmContent(storeblog.urlParams, id, utm_medium, utm_content)

    return (
        <div className="col">
            <a href={link + params} className=" article_side_banner">

                    <img className="article_side_banner-img" src={photoSRC} alt="image"/>
                    <p className="article_side_banner-title">{title}</p>
            </a>
        </div>
    );
};

export default observer(BannerItem);