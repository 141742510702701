import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {RoutesList} from "../../routes";
import SideButton from "../UI/SideButton/SideButton";


const SideBar: FC = () => {
    const {store} = useContext(Context)
    const [page, setPage] = useState(0)

    const sideButtonsArr = [
        {
            text : 'Список статтей',
            link : `${RoutesList.POSTS_LIST_ROUTER}`,
            icon : 'list-ul',
        },
        {
            text: 'Список баннерів',
            link : `${RoutesList.BANNERS_LIST_ROUTER}`,
            icon: 'layer'
        },
        {
            text:'Створити статтю',
            link:`${RoutesList.BLOG_ROUTER}`,
            icon:'receipt'
        },
        {
            text:'Створити банер',
            link:`${RoutesList.CREATE_BANNER_ROUTER}`,
            icon:'mobile-landscape'
        },
        {
            text:'POPUP банери',
            link:`${RoutesList.POPUP_BANNER_ROUTER}`,
            icon:'bookmark-heart'
        },
        {
            text:'Редагувати OS Token',
            link:`${RoutesList.ONE_SIGNAL_EDIT_TOKEN_ROUTER}`,
            icon:'key'
        }
    ]

    useEffect(()=> {
        const sideBarPage = localStorage.getItem('sideBarPage');
        sideBarPage ? setPage(+sideBarPage) : setPage(0);
    },[])

    const LogOut = () => {
        store.logout()
    }

    const logo = {img: require('../../images/logo.png')};

    return (
        <div className='sidebar-wrapper'>
            <div className='simplebar-wrapper'>
                <div className='simplebar-mask'>
                    <div className='simplebar-offset'>
                        <div className='simplebar-content-wrapper'>
                            <div className='simplebar-content mm-active'>
                                <div className="sidebar-header">
                                    <div>
                                        <Link to={RoutesList.POSTS_LIST_ROUTER}>
                                            <div className='d-flex justify-center w-full mx-auto'>
                                                <i className="fadeIn animated bx bx-unite"></i>
                                                <h4 className='text-uppercase m-1'>newsboard</h4>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <ul id='menu' className='metismenu mm-show'>
                                    <li className="menu-label">Меню</li>


                                    {
                                        sideButtonsArr.map((e,i) => {
                                            return(

                                                <li key={i}>
                                                    <SideButton
                                                        text={e.text}
                                                        link={e.link}
                                                        icon={e.icon}
                                                        active={store.sideBarActiveID === i} // Передача статусу активності до компонента SideButton
                                                        id={i}
                                                    />
                                                </li>

                                            )
                                        })
                                    }
                                    <li>

                                        <a aria-expanded="false" onClick={LogOut}>
                                            <div className="parent-icon"><i className="bx bx-exit"></i>
                                            </div>
                                            <div className="menu-title">Вийти</div>
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default observer(SideBar);
