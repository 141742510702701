import { $host } from "./index";

export const getPosts = async (page, limit, category) => {
    const { data } = await $host.post(`/api/blog/getPosts/${page}/${limit}`, { category })
    return data
}

export const getOnePosts = async (id, params) => {
    const { data } = await $host.post('/api/blog/getOnePost', { id, params })
    return data
}