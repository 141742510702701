import React, {FC, useContext, useEffect} from 'react';
import LoginForm from "../components/LoginForm";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const AuthPage: FC = () => {
    const {store} = useContext(Context)
    useEffect(()=>{

        if (localStorage.getItem('token')) {
            store.checkIsAuth().then(res => console.log(res))
        }



    },[])

    return (
        <div className="col  pt-5 min-vh-100 d-flex align-items-center ">
            <div className='col-lg-6 mx-auto'>
                <LoginForm/>
            </div>

        </div>
    );
};

export default observer(AuthPage);