import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import PopupBannerItem from "../components/PopupBannerItem/PopupBannerItem";
import PopupModalBannerItem from "../components/PopupBannerItem/PopupModalBannerItem";



const PopupBannerList: FC = () => {
    const {postStore} = useContext(Context)

    const [page, setPage] = useState(1)

    const [totalPages, setTotalPages] = useState<number[]>([])
    const limit = 1000;

    const [isShowPopup, setIsShowPopup] = useState(false);
    const [chosenBanner, setChoosenBanner] = useState(7);

    const pages = useMemo(() => {
        const pagesArray = [];
        for (let i = 0; i < postStore.bannerPages; i++) {
            pagesArray.push(i + 1);
        }
        setTotalPages(pagesArray);
        return pagesArray;
    }, [postStore.pages]);

    useEffect(() => {
        let htmlRoot = document.getElementsByTagName("html");
        htmlRoot[0].classList.add('dark-theme');

        postStore.getBanners(page, limit)
        postStore.getPopupBanners();
    }, [page,isShowPopup])


    const changeBanner = (itemNum: number) =>{
        setIsShowPopup(!isShowPopup);
        setChoosenBanner(itemNum);
        console.log(postStore.popupBannersListID , 'arrListID')
    }
    const handleClosePopupList = () => {
        setIsShowPopup(!isShowPopup)
    }
    const handleChooseBanner = (id:string) => {

        postStore.popupBannersListID[chosenBanner] = id;
        console.log(
            postStore.popupBannersListID, 'updatePopupBannerList',
            postStore.popupBannersListID[chosenBanner], 'popupBannersList[chosenBanner]',
            id,'id'
        )
        postStore.updatePopupBanners(postStore.popupBannersListID);
        // postStore.getPopupBanners();
        postStore.getPopupBanners();
        setIsShowPopup(!isShowPopup);

    }

    return (
        <div className=''>
            <div className='w-full h-1 bg-slate-400 rounded-md'></div>
            <div className=''>
                Popup Banner Items
                <hr/>

                { isShowPopup ?
                    <div className="modal show" >
                        <div className='modal-dialog' >
                            <div className='modal-content'>
                                <div  className='pt-10 d-flex flex-column align-items-center h-50 w-100 '>
                                    <ul style={{height: 500}} className="d-flex flex-column align-items-center overflow-y-scroll">
                                        {

                                            postStore.banners.map( (e,i) => {
                                                return <li key={e._id} className="list-unstyled" onClick={() => handleChooseBanner(e._id)}>
                                                    <PopupModalBannerItem img={e.mainImg} title={e.title}/>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                     : false
                }

                <div className="card">
                    <div className="column-lg-8">
                        <div className='d-flex justify-content-center flex-wrap py-4'>
                            {
                                postStore.popupBanners.map( (e,i) => {
                                    return(
                                        <div key={e._id + i} className='col-lg-4 d-flex justify-content-center' onClick={() => changeBanner(i)}>
                                            <PopupBannerItem title={e.title} img={e.mainImg}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(PopupBannerList);