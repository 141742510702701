import React, {useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import PostList from "./PostList";
import {Context} from "../../../index";

const Blog = () => {
    return (
        <div>
            <PostList/>
        </div>
    );
};

export default observer(Blog);