import React, { useContext, useEffect } from 'react';
import Footer from "./Footer/Footer";
import News from "./Header/News";
import { observer } from "mobx-react-lite";
import { useParams } from 'react-router-dom';
import { Context } from '../..';
import PostList from "./Posts/PostList";

const CategoryPage = () => {
    const { storeblog } = useContext(Context);
    const { category } = useParams();

    useEffect(() => {
        storeblog.setSelectedCategory(category?.toUpperCase());
    }, [category])


    return (
        <div>
            <div className='w-100'>
                <News />
            </div>
            <div className='w-100'>
                <div className='container mx-auto'>
                    <PostList />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default observer(CategoryPage);