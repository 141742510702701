import {$host} from "./index";

export const getBanners = async (page , limit) => {
    const {data} = await $host.get(`/api/banner/getBanners/${page}/${limit}`, )
    console.log(data, 'getBanners')
    return data
}
export const getPopupBanners = async () => {
    const {data} = await $host.get(`/api/popup/getPopupBanner`)
    return data
}


